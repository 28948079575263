import { computed, getCurrentInstance, ref, watch } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";

export default function useUsersList() {
	// Use toast
	const toast = useToast();
	const refUserListTable = ref(null);
	let instance = getCurrentInstance().proxy;
	// Table Handlers
	const perPage = ref(10);
	const totalUsers = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];

	const sortBy = ref("id");
	const isSortDirDesc = ref(true);

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		};
	});

	const refetchData = () => {
		refUserListTable.value.refresh();
	};

	watch([currentPage, perPage], () => {
		refetchData();
	});
	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserRoleVariant = (role) => {
		if (role === "超级管理员") {
			return "primary";
		}
		if (role === "中级管理员") {
			return "warning";
		}
		if (role === "maintainer") {
			return "success";
		}
		if (role === "editor") return "info";
		if (role === "admin") {
			return "danger";
		}
		return "primary";
	};

	const resolveUserRoleIcon = (role) => {
		if (role === "超级管理员") {
			return "UserIcon";
		}
		if (role === "中级管理员") {
			return "SettingsIcon";
		}
		if (role === "maintainer") {
			return "DatabaseIcon";
		}
		if (role === "editor") {
			return "Edit2Icon";
		}
		if (role === "admin") {
			return "ServerIcon";
		}
		return "UserIcon";
	};

	return {
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		sortBy,
		isSortDirDesc,
		refUserListTable,
		resolveUserRoleVariant,
		resolveUserRoleIcon,
		refetchData,
	};
}
